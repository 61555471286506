.layout {
  height: 100vh;
  overflow: hidden;
}

.parcelado-usa,
.tazapay,
.cmi-button {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.cmi-button {
  background-color: #fff;
  color: red;
}

.tazapay {
  background-color: #103747;
}

.amazon-pay {
  width: 100%;
}

.parcelado-usa {
  background-color: #002776;
}

.button-container {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}